.presentation-container {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: 50px 0 0;
    padding: 20px;
    gap: 0.5rem;
}

.btn-download {
    width: max-content;
    display: inline-block;
    color: #4db5ff;
    padding: 0.75rem;
    border-radius: 0.4rem;
    cursor: pointer;
    /* --> cursor is the mouse pointer, and poiter changes to the little hand*/
    border: 1px solid #ffffff;
}

.btn-download:hover {
    background: rgba(255, 255, 255, 0.07);
    color: #4db5ff;
    border-color: rgba(255, 255, 255, 0.2);
}


.btn-contact {
    background: #4db5ff;
    width: max-content;
    display: inline-block;
    color: #1f1f38;
    padding: 0.75rem;
    border-radius: 0.4rem;
    cursor: pointer;
}

.cv-container {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

.img-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px;
    padding: 10px;
}

.img-back {
    display: flex;
    background: linear-gradient(#4db5ff32, transparent);
    height: max-content;
    border-radius: 100% 100% 0 0;
    flex-shrink: 1;
}

img {
    height: auto;
    max-width: 95%;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.382));
}


h5 {
    font-size: 18px;
    font-weight: 200;
}

h1 {
    font-size: 35px;
    font-weight: 600;
}


.header-socials {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header-socials a {
    color: #4db5ff;
    font-size: 25px;
    margin: 4px;
    margin-top: 5px;
}

.header-socials a:hover {
    color: #ffffff;
}



/* Divs arround the image */

.space-arround {
    margin: 20px;
    width: 10px;
    height: 502px;
    box-sizing: border-box;
    flex-shrink: 8;
}

.space-one {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.space-two {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.scroll-down {
    width: max-content;
    transform: rotate(90deg);
    color: #4db5ff;
    margin-top: 80px;
    margin-left: -44px;
    position: absolute;
    bottom: 53px;
    font-weight: 400;
    font-size: large;

}

@media screen and (max-width: 600px) {

    .cv-container {
        padding: 0 20px 20px;
    }

    .header-socials {
        display: none;
    }

    .presentation-container {
        margin: 40px 0 0;
    }

    .presentation-container h1 {
        font-size: 26px;
        font-weight: 400;
    }

    .cv-container {
        padding: 20px;
    }

    .img-container {
        margin: 0px;
    }

    .img-back {
        margin: 0 30px;
    }

    .space-arround {
        display: none;
    }
}