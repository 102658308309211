/* .nav {
  background: rgba(0, 0, 0, 0.3);
  width: max-content;
  margin-left: 10px;
  padding: 5px;
  z-index: 2;
  position: fixed;
  transform: translateY(-80%);
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  border-radius: 3rem;
  backdrop-filter: blur(4px);
  /*Filtro para o que se vê por debaixo de um elemento*/
/* }  */

.nav {
  background: rgba(0, 0, 0, 0.4);
  width: max-content;
  display: flex;
  flex-direction: row;
  padding: 5px;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  gap: 5rem;
  border-radius: 3rem;
  backdrop-filter: blur(4px);
}

.nav a {
  background: transparent;
  padding: 0.7rem;
  border-radius: 50%;
  color: rgb(160, 161, 255);
  font-size: 1.1rem;
  direction: none;
  display: flex;
  font-size: 1.1rem;
}

.nav a:hover {
  background: rgba(0, 251, 247, 0.563);
  color: #fff;
}



@media screen and (max-width: 700px) {
  .nav {
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: flex;
    flex-direction: row;
    padding: 5px;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    gap: 2rem;
  }

  .nav a {
    font-size: 1.1rem;
    padding: 0.7rem;
  }

}

@media screen and (max-width: 350px) {

  .nav {
    padding: 5px;
    left: 47%;
    position: fixed;
  }
}