.works-div {
  font-size: 20px;
  font-weight: 200;
  color: #8acdfe;
  animation: jump 300ms linear;
  padding: 25px;
}

.projects-div {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.project-1 {
  box-sizing: border-box;
  margin: 10px 5px;
}

.project-1 img {
  height: 200px;
  width: 300px;
  filter: opacity(1);
  transition: all ease 300ms;
  border-radius: 20px;
}

.project-1 img:hover {
  transform: scale(0.98);
  filter: opacity(0.8);
}

@media screen and (max-width: 840px) {
  .project-1 img {
    height: 150px;
    width: 250px;
  }
}

@media screen and (max-width: 650px) {
  .project-1 img {
    height: 250px;
    width: 320px;
  }
}

.experience-div {
  animation: jump 300ms linear;
}

.experience-icon {
  font-size: 61px;
  margin: 20px;
}

/*skills icons*/

.ico-html {
  color: #f1662b;
}

.ico-css {
  color: #2992c5;
}

.ico-js {
  color: #e5a32b;
}

.ico-react {
  color: #61dbfb;
}

.ico-gatsby {
  color: #4575ef;
}

.ico-node {
  color: #83cd29;
}

.ico-py {
  color: #221fc6;
}

.ico-ql {
  color: rgb(250, 137, 137);
}

.ico-strapi {
  color: rgb(135, 111, 242);
}

.ico-cont {
  color: rgb(84, 104, 255);
}

.ico-mui {
  color: rgb(0, 129, 203);
}

.ico-styled {
  color: rgb(221, 111, 179);
}

.ico-redux {
  color: rgb(119, 73, 189);
}

.ico-wp {
  color: rgb(27, 118, 156);
}

.ico-db {
  color: rgb(7, 171, 79);
}

.ico-sql {
  color: rgb(1, 113, 165);
}

.ico-prisma {
  color: rgb(5, 107, 165);
}

@keyframes jump {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@media screen and (max-width: 480px) {

  .experience-div {
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
  }

}

@media screen and (max-width: 380px) {
  .experience-icon {
    font-size: 40px;
  }

  .projects-div,
  .clients-div {
    font-size: 12px;
  }
}

@media screen and (max-width: 300px) {
  .works-div {
    padding: 5px;
  }
}